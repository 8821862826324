import React from "react";
import { Grid, Container, Box, Typography } from "@mui/material";

import { Logo } from "../Logo";

import { content } from "./Header.content";

export const Header: React.FC = () => {
  return (
    <Box display="flex" justifyContent={`center`}>
      <Logo />
    </Box>
  );
};
