import React from "react";
import { Box } from "@mui/material";

import { Parallax } from "../Parallax";
import { Header } from "../Header";
import { Footer } from "../Footer";

interface LayoutProviderProps {
  children: React.ReactNode;
}

export const LayoutProvider: React.FC<LayoutProviderProps> = ({ children }) => {
  return (
    <Parallax>
      <Box>
        <Header />
        {children}
        <Footer />
      </Box>
    </Parallax>
  );
};
