import React, { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

import ImgBg from "../../images/bg-100.jpg";

interface ParallaxProps {
  children: React.ReactNode;
}

export const Parallax: React.FC<ParallaxProps> = ({ children }) => {
  const [offsetY, setOffsetY] = useState<number>(0);

  const parallaxBackgroundImage = ImgBg; // Replace with your image URL

  const handleScroll = () => {
    setOffsetY(window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Parallax Background */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${parallaxBackgroundImage})`,
          backgroundPosition: `center ${offsetY * 0.5}px`, // Adjust the 0.5 value to control the speed
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          zIndex: -1,
        }}
      />
      {children}
    </Box>
  );
};
