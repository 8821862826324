import React from "react";
import { navigate, Link } from "gatsby";
import {
  Grid,
  Box,
  Typography,
  useTheme,
  Link as MuiLink,
  useMediaQuery,
} from "@mui/material";

import { Segment } from "../../components/Segment";

import ImgLogoFooter from "../../images/logo-footer.svg";

export const Footer: React.FC = () => {
  const isSmall = useMediaQuery(useTheme().breakpoints.down("sm"));
  return (
    <>
      <Segment variant="primary">
        <Grid container>
          <Grid item xs={12} md={4}>
            <Box textAlign={isSmall ? `center` : `left`}>
              <Typography>© 2023 Wutif LLC All rights reserved. </Typography>
              <Link to="/privacy-policy">
                <Typography color="secondary">Privacy Policy</Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box textAlign={`center`} my={isSmall ? 4 : 0}>
              <Box
                component="img"
                src={ImgLogoFooter}
                alt="logo footer"
                width={isSmall ? 220 : 320}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}></Grid>
        </Grid>
      </Segment>
    </>
  );
};
